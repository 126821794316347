import React, { useMemo, useState, useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import capitalize from "lodash/capitalize";

import { Input } from "~/components/Input";
import { PrimaryButton } from "~/components/Button";

import { Group, UserRole, usePeopleContext } from "./context";

const useStyles = makeStyles(({ palette, dashboard }) => {
    const inputPadding = "15px";

    return {
        container: {
            // width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            margin: `${dashboard?.innerContent?.paddingTop || 0}px 20px 20px`,
        },
        inputControl: {
            padding: inputPadding,
            paddingRight: 0,
            width: "100%",
        },
        inputBoxRoot: {
            backgroundColor: palette.background.paper,
        },
        inputBoxLabel: {
            color: `${palette.text.primary} !important`,
        },
        inviteTable: {
            borderSpacing: 0,
            borderCollapse: "collapse",
            "& td": {
                height: "100%",
            },
        },
        inviteInputControl: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            marginRight: "0px !important",
        },
        inviteInputRoot: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        roleSelectControl: {
            "& > div": {
                borderRadius: 0,
            },
        },
        roleSelectRoot: {
            margin: "5px 0",
        },
        roleFieldSet: {
            "& > div > fieldset": {
                borderLeft: "none !important",
                outline: "none",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgba(0, 0, 0, 0.23)",
            },
        },
        roleIcon: {
            top: "calc(50% - 14px)",
        },
        roleWidth: {
            width: 118,
        },
        roleMenu: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        inviteButton: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            padding: "10px 24px",
            height: "100%",
            width: 98,
        },
        inviteButtonLabel: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
        },
    };
});

const InputBox = withStyles((theme) => ({
    root: {
        color: theme.palette.background.paper,
    },
}))(Input);

interface IControlBarProps {
    loading: boolean;
    group?: Group;
}

export const ControlBar: React.FC<IControlBarProps> = ({
    loading = false,
    group,
}) => {
    const styles = useStyles();
    const { filterUsers, addUser, readOnly } = usePeopleContext();
    const [curFilter, setCurFilter] = useState("");
    const [selectedRole, changeRole] = useState<UserRole>(UserRole.Member);
    const [newUser, setNewUser] = useState("");
    const newUserRef = useRef<HTMLInputElement | null>(null);

    const runFilter = useMemo(() => (
        debounce(filterUsers, 500)
    ), [filterUsers]);

    const doFilter = useCallback((filter: string) => {
        setCurFilter(filter);
        runFilter({ filter });
    }, [runFilter]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        changeRole(event.target.value as UserRole);
    };

    const resetInput = () => {
        const el = newUserRef.current;
        setNewUser("");
        if (el) el.value = "";
    };

    return (
        <Box className={styles.container}>
            <Box>
                <InputBox
                    id="people-list-search"
                    inputLabel="Search"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={false}
                    value={curFilter}
                    disabled={!group}
                    InputLabelProps={{
                        className: styles.inputBoxLabel,
                        classes: {
                            focused: styles.inputBoxLabel,
                        },
                    }}
                    InputProps={{
                        color: "secondary",
                        classes: {
                            input: styles.inputControl,
                            root: styles.inputBoxRoot,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{
                                    visibility: curFilter ? "visible" : "hidden",
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        doFilter("");
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}

                    onChange={(e) => {
                        doFilter(e.currentTarget.value);
                    }}
                />
            </Box>
            <form onSubmit={(e) => { e.preventDefault(); }}>
                <table
                    style={{
                        visibility: readOnly ? "hidden" : undefined,
                    }}
                    className={styles.inviteTable}
                    cellPadding={0}
                    cellSpacing={0}
                >
                    <tbody>
                        <tr>
                            <td>
                                <InputBox
                                    id="people-add-to-group"
                                    placeholder="Invite by email"
                                    variant="outlined"
                                    fullWidth={false}
                                    disabled={readOnly || !group}
                                    InputLabelProps={{
                                        className: styles.inputBoxLabel,
                                        classes: {
                                            focused: styles.inputBoxLabel,
                                        },
                                    }}
                                    classes={{
                                        root: styles.inviteInputControl,
                                    }}
                                    InputProps={{
                                        color: "secondary",
                                        classes: {
                                            input: styles.inputControl,
                                            root: clsx(styles.inputBoxRoot, styles.inviteInputRoot),
                                        },
                                    }}
                                    inputRef={newUserRef}

                                    onChange={(e) => {
                                        setNewUser(e.currentTarget.value);
                                    }}
                                />
                            </td><td>
                                <FormControl
                                    variant="outlined"
                                    className={clsx(
                                        styles.inputBoxRoot,
                                        styles.roleSelectControl,
                                    )}
                                    classes={{
                                        root: clsx(
                                            styles.roleFieldSet,
                                            styles.roleWidth,
                                        ),
                                    }}
                                >
                                    <Select
                                        id="select-user-role"
                                        autoWidth
                                        value={selectedRole}
                                        onChange={handleChange}
                                        disabled={readOnly || !group}
                                        inputProps={{
                                            className: styles.inputControl,
                                        }}
                                        classes={{
                                            root: styles.roleWidth,
                                            selectMenu: styles.roleWidth,
                                            icon: styles.roleIcon,
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                            },
                                            getContentAnchorEl: null,
                                            classes: {
                                                paper: styles.roleMenu,
                                                list: styles.roleWidth,
                                            },
                                        }}
                                    >
                                        {Object.values(UserRole).map((role) => (
                                            <MenuItem
                                                key={`user-role-${role}`}
                                                value={role}
                                            >
                                                {capitalize(role)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </td><td>
                                <PrimaryButton
                                    type="submit"
                                    className={styles.inviteButton}
                                    disabled={readOnly || !group}
                                    classes={{
                                        label: styles.inviteButtonLabel,
                                    }}
                                    onClick={() => {
                                        if (loading || !newUser) return;
                                        addUser({
                                            username: newUser,
                                            admin: selectedRole === UserRole.Admin,
                                            groupId: group!.id!,
                                        }).then(() => {
                                            resetInput();
                                        });
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={"1.5em"}
                                            style={{
                                                color: "#FFFFFF",
                                            }}
                                        />
                                    ) : "Invite"}
                                </PrimaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Box>
    );
};