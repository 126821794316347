import React from "react";

import { Typography } from "~/components/Typography";
import { DisplayOverride } from "./DisplayOverride";

export const AutocorpBlock: React.FC = () => (
    <DisplayOverride>
        <Typography variant="h1" component="div">
            {"As an Autocorp Admin, you must select a company first"}
        </Typography>
    </DisplayOverride>
);