import React from "react";
import { createSettingsView, ViewComponent } from "@settings/view";

import { PeopleProvider } from "./_lib/context";
import { UserDisplay } from "./_lib/UserDisplay";

const PeopleView: ViewComponent = () => {
    return (
        <PeopleProvider>
            <UserDisplay />
        </PeopleProvider>
    );
};

export default createSettingsView(PeopleView, {
    title: "People",
    internalScroll: true,
    internalPad: true,
});