import React, { Fragment, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import isNil from "lodash/isNil";

import { Table } from "~/components/Table";
import CompanyPanel from "~/components/CompanyPanel";
import { GlobalLoader } from "@settings/GlobalLoader";
import { AutocorpBlock } from "@settings/AutocorpBlock";
import { CriticalError } from "@settings/CriticalError";

import { ControlBar } from "./ControlBar";

import { useAuth } from "@api/auth";

import { userColumns } from "./columns";
import { usePeopleContext } from "./context";

const useStyles = makeStyles(({ shadows, shape }) => ({
    content: {
        position: "relative",
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
    },
    userContent: {
        width: "100%",
        minHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column nowrap",
        position: "relative",
    },
    overrideDisplay: {
        display: "flex",
        padding: "5%",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
}));

export const UserDisplay: React.FC = () => {
    const { user, selectedCompany, changeCompany } = useAuth();
    const {
        peopleLoading,
        criticalError,
        displayGroup,
        groupLoading,
        filter,
    } = usePeopleContext();
    const styles = useStyles();

    const content = useMemo(() => {
        if (peopleLoading) {
            return (
                <GlobalLoader />
            );
        } else if (user?.autocorpAdmin && !selectedCompany) {
            return (
                <AutocorpBlock />
            );
        } else if (criticalError) {
            return (
                <CriticalError
                    message={"An error occurred while fetching the user list"}
                    error={criticalError}
                />
            );
        } else {
            return (
                <Fragment>
                    <ControlBar
                        group={displayGroup?.group}
                        loading={groupLoading[displayGroup?.group.id!]}
                    />
                    <Table
                        compact
                        columns={userColumns}
                        rows={displayGroup?.rows || []}
                        loading={peopleLoading}
                        emptyMessage={(filter
                            ? "No users found matching that critera"
                            : "This company does not have any users"
                        )}
                    />
                </Fragment>
            );
        }
    }, [
        criticalError,
        displayGroup,
        filter,
        groupLoading,
        peopleLoading,
        user,
        selectedCompany,
    ]);

    return (
        <Box className={styles.content}>
            {(user?.companies || []).length > 1 && (
                <CompanyPanel
                    subtitle={(item) => (
                        isNil(item?.userCount)
                            ? ""
                            : `${item?.userCount} users`
                    )}
                    placement="inline"
                />
            )}
            <Box className={styles.userContent}>
                {content}
            </Box>
        </Box>
    );
};