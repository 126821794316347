import { createPrivateView, ViewComponent } from "~/model/view/private";
import { SettingsLayout } from "@settings/SettingsLayout";

export const createSettingsView: typeof createPrivateView = (
    component,
    props = {},
) => {
    return createPrivateView(
        component,
        {
            ...props,
            showBack: false,
            title: "Settings",
            layoutProps: {
                topNav: {
                    sub: SettingsLayout,
                },
            },
        },
    );
};

export type { ViewComponent };