import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { UserStatus } from "~/model/users";

const useStyles = makeStyles(() => ({
    container: {
        border: "1px solid black",
        borderRadius: 3,
        padding: "2px 8px",
    },
}));

interface IStatusProps {
    status: UserStatus;
}

const getStatusDisplay = (status: UserStatus) => {
    switch (status) {
        case UserStatus.Disabled: {
            return "Disabled";
        }
        case UserStatus.Pending: {
            return "Pending";
        }
        default: {
            return "";
        }
    }
};

export const Status: React.FC<IStatusProps> = ({
    status,
}) => {
    const styles = useStyles();
    const displayStatus = getStatusDisplay(status);

    if (!displayStatus) return null;

    return (
        <Box className={styles.container}>
            {displayStatus}
        </Box>
    );
};