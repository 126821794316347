import React from "react";

import { LayoutComponent } from "~/model/layout";
import  TabbedNav from "~/components/TabbebNav";
import { useAuth } from "@api/auth";
import { ITabProps } from "~/components/Tab/types";

export const SettingsLayout: LayoutComponent<never> = ({ location }) => {
    const {
        user,
    } = useAuth();
    const isAutocorpAdmin = user?.autocorpAdmin;
    const adminTabs: ITabProps[] = isAutocorpAdmin ? [{
        location,
        title: "General",
        path: "/settings/general",
    }] : [];

    return (
        <TabbedNav
            tabs={[
                ...adminTabs,
                {
                    title: "Delivery Methods",
                    path: "/settings/delivery-methods",
                },
                {
                    title: "Manage People",
                    path: "/settings/people",
                },
                {
                    title: "Analytics Tracking",
                    path: "/settings/analytics-tracking",
                },
            ]}
        />
    );
};